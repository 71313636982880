.modalHeader {
  font-size: 18pt;
  font-weight: medium;
  color: #FFFFFF;
}

.iconsText {
  font-size: 16pt;
  color: #D7D7D7;
  padding-bottom: 20px;
  margin-right: 15px;
}
